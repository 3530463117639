import {useState} from "react";
import InputMask from "react-input-mask";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import {Link} from "react-router-dom";

export default function Subscribe(){
    const [formStep, setFormStep] = useState(0);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const schemaObj = {
        email: yup.string().email('Некорректный E-mail').required('Некорректный E-mail'),
        name: yup.string().required('Некорректное имя'),
        phone:
            yup.string()
                .test("len", "Некорректный телефон", (val) => {
                    const val_length_without_dashes = val.replace('+', '').replace(/-|_| /g, "").length;
                    return (val_length_without_dashes === 11 || val_length_without_dashes === 0);
                })
                .nullable(),
        agree: yup.boolean().oneOf([true], 'Необходимо согласие'),
    };

    const schema = yup.object(schemaObj).required();

    const { register, clearErrors, setValue, watch, setFocus, setError, formState: { errors }, handleSubmit } = useForm({
        resolver: yupResolver(schema)
    });

    const watchPhone = watch("phone");

    const onSubmit = async (data) => {
        setIsSubmitting(true);
        const formData = {
            "_member_email": data.email,
            "q295": data.name,
            "q173": "Город",
            "q61" : data.phone,
            "q147": "resetCode",
            "bt_save": "Сохранить"
        };

        await fetch('https://sendsay.ru/form/kinder/35/', {
            method: "POST",
            body: JSON.stringify(formData),
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json"
            },
        }).then((response) => response.json()).then((response) => {
            setFormStep(2);
            setIsSubmitting(false);
        });
    }

    return(
        <div className="modal__ct" data-step={formStep}>
            {formStep == 0 && (
                <>
                    <div className="modal__title text-center text-uppercase">
                        Всегда рядом
                    </div>
                    <div className="modal__subtitle text-uppercase text-center">
                        хотите получать рассылку <br/>
                        и первыми узнавать <br/>
                        всё самое интересное?
                    </div>
                    <div className="modal__action d-flex justify-content-center">
                        <div onClick={()=>{
                            setFormStep(1);
                        }} className="modal__btn d-flex align-items-center justify-content-center">
                            <div className="modal__btn-bg">
                                <svg width="212" height="65" viewBox="0 0 212 65" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M197.619 55.6019C200.429 54.1003 202.971 52.2173 205.156 50.0181C216.08 38.4378 210.447 21.9154 198.405 12.3064C179.158 -2.81306 131.897 4.42121 108.136 7.05268C89.3426 9.10651 70.7709 11.9764 52.3 15.1855C37.3358 17.7344 12.9195 19.0456 3.23558 29.2322C-3.1834 36.0539 0.424083 43.3615 9.86613 47.2216L12.5667 48.2118C50.6271 61.6808 99.9233 66.0361 141.551 64.8075C158.228 64.3949 182.645 63.5696 197.619 55.6019Z" fill="#ACACAC"/>
                                    <path d="M198.619 52.5991C201.43 51.0975 203.971 49.2146 206.157 47.0153C217.08 35.435 211.447 18.9126 199.406 9.30365C180.159 -5.81584 132.898 1.41843 109.137 4.0499C90.3433 6.10373 71.7716 8.97359 53.3007 12.1827C38.3365 14.7316 13.9202 16.0428 4.23631 26.2294C-2.18267 33.0511 1.42482 40.3587 10.8669 44.2188L13.5675 45.209C51.6279 58.6781 100.924 63.0333 142.552 61.8047C159.229 61.3921 183.645 60.5669 198.619 52.5991Z" fill="#E8451B"/>
                                </svg>
                            </div>
                            <span>Хочу</span>
                        </div>
                    </div>
                </>
            )}

            {formStep == 1 && (
                <>
                    <div className="modal__title text-center text-uppercase">
                        Всегда рядом
                    </div>
                    <div className="modal__subtitle modal__subtitle--blue text-uppercase text-center fadeIn animated">
                        Оставьте свои данные, <br/>
                        чтобы первыми узнать обо всех новостях
                    </div>

                    <form onSubmit={handleSubmit(onSubmit)} className={'modal__form fadeIn animated' + (isSubmitting ? ' form--loading' : '')}>
                        <div className="form__list">
                            <div className="form__it">
                                <label htmlFor="email" className="form__label">
                                    E-mail <span>*</span>
                                </label>
                                <div className={'form__field' + (errors.email ? ' has-error' : '')}>
                                    <input
                                        type="text"
                                        id="email"
                                        autoComplete="nope"
                                        {...register("email")}
                                    />

                                </div>
                                {errors.email && (
                                    <p className="error__message">
                                        {errors.email?.message}
                                    </p>
                                )}
                            </div>
                            <div className="form__it">
                                <label htmlFor="name" className="form__label">
                                    Имя <span>*</span>
                                </label>
                                <div className={'form__field' + (errors.name ? ' has-error' : '')}>
                                    <input
                                        type="text"
                                        id="name"
                                        autoComplete="nope"
                                        {...register("name")}
                                    />

                                </div>
                                {errors.name && (
                                    <p className="error__message">
                                        {errors.name?.message}
                                    </p>
                                )}
                            </div>
                            <div className="form__it form__it--phone">
                                <label htmlFor="phone" className="form__label">
                                    Телефон
                                </label>
                                <div className={'form__field' + (errors.phone ? ' has-error' : '')}>
                                    <InputMask
                                        autoComplete="nope"
                                        type="text"
                                        id="phone"
                                        mask="+7 999-999-99-99"
                                        alwaysShowMask={false}
                                        placeholder="+7"
                                        {...register("phone", {
                                            onBlur: (e) => {
                                                const val_length_without_dashes = watchPhone.replace('+', '').replace(/-|_| /g, "").length;
                                                if (val_length_without_dashes < 11) {
                                                    setValue('phone', '');
                                                }
                                            }
                                        })}
                                    />

                                </div>
                                {errors.phone && (
                                    <p className="error__message">
                                        {errors.phone?.message}
                                    </p>
                                )}
                            </div>
                            <div className="form__it">
                                <div className="checkbox__it">
                                    <div className={'checkbox__wrap' + (errors.agree ? ' has-error' : '')}>
                                        <label className="align-items-start">
                                            <input
                                                type="checkbox"
                                                {...register("agree")}
                                            />
                                            <div className="checkbox__decor"/>
                                            <div className="checkbox__title">
                                                Я даю согласие на обработку <Link to="/politika-v-otnoshenii-obrabotki-personalnykh-dannykh">персональных данных</Link>
                                            </div>
                                        </label>
                                    </div>
                                    {errors.agree && (
                                        <p className="error__message">
                                            {errors.agree?.message}
                                        </p>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="modal__action d-flex justify-content-center">
                            <button disabled={isSubmitting} type="submit" className="modal__btn modal__btn--small d-flex align-items-center justify-content-center">
                                <div className="modal__btn-bg">
                                    <svg width="212" height="65" viewBox="0 0 212 65" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M197.619 55.6019C200.429 54.1003 202.971 52.2173 205.156 50.0181C216.08 38.4378 210.447 21.9154 198.405 12.3064C179.158 -2.81306 131.897 4.42121 108.136 7.05268C89.3426 9.10651 70.7709 11.9764 52.3 15.1855C37.3358 17.7344 12.9195 19.0456 3.23558 29.2322C-3.1834 36.0539 0.424083 43.3615 9.86613 47.2216L12.5667 48.2118C50.6271 61.6808 99.9233 66.0361 141.551 64.8075C158.228 64.3949 182.645 63.5696 197.619 55.6019Z" fill="#ACACAC"/>
                                        <path d="M198.619 52.5991C201.43 51.0975 203.971 49.2146 206.157 47.0153C217.08 35.435 211.447 18.9126 199.406 9.30365C180.159 -5.81584 132.898 1.41843 109.137 4.0499C90.3433 6.10373 71.7716 8.97359 53.3007 12.1827C38.3365 14.7316 13.9202 16.0428 4.23631 26.2294C-2.18267 33.0511 1.42482 40.3587 10.8669 44.2188L13.5675 45.209C51.6279 58.6781 100.924 63.0333 142.552 61.8047C159.229 61.3921 183.645 60.5669 198.619 52.5991Z" fill="#E8451B"/>
                                    </svg>
                                </div>
                                <span>Подписаться</span>
                            </button>
                        </div>
                    </form>
                </>
            )}

            {formStep == 2 && (
                <>
                    <div className="modal__title text-center text-uppercase">
                        рады что вы с нами
                    </div>
                    <div className="modal__subtitle modal__subtitle--blue text-uppercase text-center fadeIn animated">
                        Пожалуйста, <br/>
                        подтвердите подписку <br/>
                        на почте
                    </div>
                </>
            )}
        </div>
    )
}