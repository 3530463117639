import React, {FunctionComponent, Fragment, useEffect} from 'react';
import ReactDOM from 'react-dom';
import { Dialog, Transition } from '@headlessui/react'

export const Modal = ({
    isShown,
    modalContent,
    hide,
    className,
  }) => {

    useEffect(()=>{
        if(isShown){
            setTimeout(function (){
                if(document.getElementById('modal__content')){
                    document.getElementById('modal__content').scroll(0, 0);
                }
            }, 100);
        }
    }, [isShown]);

    return(
        <Transition appear show={isShown} as={Fragment}>
            {/* Modal backdrop */}
            <Dialog as="div" className={'modal ' + className} onClose={()=>{
                if(!className.includes('modal--locked')) {
                    //hide()
                }
            }}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div id="modal__backdrop" className="modal__backdrop" />
                </Transition.Child>

                {/* Modal content */}
                <div id="modal__content" className="modal__content">
                    <div className="modal__content-area">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-500"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-200"
                            leave="ease-in duration-150"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <Dialog.Panel className="modal__content-box">
                                <div className="modal__content-box-bl">
                                    {!className.includes('modal--locked') && (
                                        <div
                                            id="modal-close"
                                            className="modal__close"
                                            onClick={() => {
                                                hide();
                                            }}
                                        >
                                            <svg width="40" height="34" viewBox="0 0 40 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M5.70498 30.8852C7.73777 24.5246 14.623 19.4754 19.8689 15.7377C25.246 11.8689 31.0165 8.65574 36.7214 5.37705C38.8853 4.13115 36.9181 0.786886 34.7542 2.03279C28.0656 5.77049 21.4427 9.57377 15.4099 14.1639C10.0329 18.2951 4.00007 23.2131 1.90171 29.8361C1.18039 32.2623 4.9181 33.3115 5.70498 30.8852Z" fill="#00A7E3"/>
                                                <path d="M0.918003 3.67213C14.3606 11.6066 26.4262 21.6393 36.7213 33.3771C38.3606 35.2787 41.1147 32.5246 39.4754 30.623C28.9836 18.623 16.6557 8.39345 2.88522 0.262298C0.721282 -0.983603 -1.24593 2.36066 0.918003 3.67213Z" fill="#00A7E3"/>
                                            </svg>
                                        </div>
                                    )}

                                    {modalContent}
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
    )
};
